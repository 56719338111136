'use client';

import Image from 'next/image';
import { object } from 'prop-types';
import { useMemo } from 'react';

import { useLocalizedPathname } from '@personly/libs-hooks';
import { useSchemes } from '@personly/libs-providers';

const ProjectLogo = ({ languages }) => {
  const localizedPathname = useLocalizedPathname(languages);
  const { schemeHandle } = useSchemes();

  const href = useMemo(() => {
    const base = process.env.NEXT_PUBLIC_SPLASH_URL;
    return new URL(localizedPathname('/'), base).href;
  }, [localizedPathname]);

  return (
    <a href={href}>
      <div className={'flex flex-row text-center justify-center items-center'}>
        <div className="flex flex-row items-center">
          <div className="flex pr-2.5 items-center">
            <Image
              src={`/images/brands/personly-logo.svg`}
              width={40}
              height={40}
              alt="personly"
            />
          </div>
          <div className="flex items-center">
            <Image
              src={`/images/brands/personly-heading-${schemeHandle.toLowerCase()}.svg`}
              width={104}
              height={26}
              alt="personly"
            />
          </div>
        </div>
      </div>
    </a>
  );
};

ProjectLogo.propTypes = {
  languages: object.isRequired,
};

export default ProjectLogo;
